export const config = {
  api_base: 'https://prod.diningtek.com/api/v1',
    api_root : 'https://prod.diningtek.com',
    logo_img_root : '',
    banner_img_root : '',
    resid : '',
    key_value : 'gyroskingaurora',
    secret_value : 'gyroskingaurora',
    iframe_root : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3071.8684344797493!2d-104.81480868462721!3d39.65267537946127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c8845a2138787%3A0xf6841e5979cf3eb!2sGyros%20King!5e0!3m2!1sen!2sin!4v1626090878533!5m2!1sen!2sin',
    facebook_link:'#',
    RestaurantName:"Gyros King Aurora"
};
